<template>
  <div>
    <div id="support-page">
      <div class="section-inner">
        <div class="sp-content">
          <p class="sp-title">온라인상담</p>
          <!--            <p class="sp-title">온라인상담 <span>신청서</span></p>-->
          <p class="sp-desc">아래 텔레그렘으로 문의주시면 확인 후 빠른시일내에 답변드리겠습니다.</p>
          <p class="sp-tele">텔레그램 상담 @SOLLINE77</p>
          <!--            <div class="input-layout">-->
          <!--              <input type="text" placeholder="제목"/>-->
          <!--              <input type="text" placeholder="성함"/>-->
          <!--              <input type="text" placeholder="연락처"/>-->
          <!--              <input type="text" placeholder="이메일주소"/>-->
          <!--              <textarea placeholder="문의내용"/>-->
          <!--              <div class="checkbox-layout">-->
          <!--                <input type="checkbox"/>-->
          <!--                <p>입력하신 개인정보(성함, 연락처, 이메일주소)는 문의글에 대한 답변을 위한 수집으로 1년간 보관 후 폐기되는것을 동의합니다.</p>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="btn-layout">-->
          <!--              <button>보내기</button>-->
          <!--            </div>-->
        </div>
      </div>
    </div>
    <div id="Footer">
      <div class="logo-layout">
        <img src="../assets/images/logo2.png"/>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Footer",
  }
</script>

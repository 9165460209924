<template>
  <div>
    <section id="service-s1">
      <div class="section-inner">
        <div class="s1-box">
          <div class="hr"/>
          <p class="title">주요서비스</p>
          <p class="desc">
            저희 SOL-LINE은 각 개발분야별 해당 전문가가<br/>
            구축 목적과 기능들을 완벽히 분석하여,<br/>
            고객의 요구사항이 최상의 결과물로 실현될 수 있도록 열정을 다하고 있습니다.
          </p>
          <div class="icon-box-layout">
            <div class="icon-box" v-for="b in S1_DATA" :key="b.id">
              <div class="img-layout">
                <img width="96px" height="96px" :src="require(`../assets/images/service/s1-icon-${b.id}.png`)"/>
              </div>
              <p>{{b.text}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="service-s2">
      <div class="section-inner">
        <p class="section-title">Main Service</p>
        <div class="ss2-box">
          <div class="content-box">
            <div class="box" v-for="m in S2_DATA" :key="m.id">
              <div class="img-layout">
                <img :src="require(`../assets/images/service/s2-${m.id}.png`)"/>
              </div>
              <div class="box-bottom">
                <p class="bb-theme">{{m.theme}}</p>
                <p class="bb-title">{{m.title}}</p>
                <ul>
                  <li v-for="v in m.list" :key="v.id">
                    <img width="10px" height="10px" src="../assets/images/home/icon_diamond.png"/>
                    <p v-html="v.content"/>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="ss2-swiper">
          <swiper class="swiper content-box" :options="swiperOption">
            <swiper-slide v-for="m in S2_DATA" :key="m.id">
              <div class="box">
                <div class="img-layout">
                  <img :src="require(`../assets/images/service/s2-${m.id}.png`)"/>
                </div>
                <div class="box-bottom">
                  <p class="bb-theme">{{m.theme}}</p>
                  <p class="bb-title">{{m.title}}</p>
                  <ul>
                    <li v-for="v in m.list" :key="v.id">
                      <img width="10px" height="10px" src="../assets/images/home/icon_diamond.png"/>
                      <p v-html="v.content"/>
                    </li>
                  </ul>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import {swiper, swiperSlide} from 'vue-awesome-swiper'
  import 'swiper/swiper-bundle.css'

  const S1_DATA = [{
    id: 1, text: 'ERP',
  }, {
    id: 2, text: 'Web Application',
  }, {
    id: 3, text: 'Crypto Currency Solution',
  }, {
    id: 4, text: 'Mobile Platform',
  },
//    {
//    id: 5, text: 'IoT',
//  },
  ]

  const S2_DATA = [{
    id: 1, theme: 'Blockchain Core Implementation',
    title: '블록체인 기반 기술 구현',
    list: [{
      id: 1, content: '블록체인 노드 구축'
    }, {
      id: 2, content: '신규 가상화폐 개발'
    }, {
      id: 3, content: '디지털 전자지갑 개발'
    }, {
      id: 4, content: '블록체인 탐색기 개발'
    }, {
      id: 5, content: '블록체인 RPC 통신 모듈 개발'
    },]
  }, {
    id: 2, theme: 'Crypto Currency Solution',
    title: '가상화폐 생태계 구축 및 솔루션 개발',
    list: [{
      id: 1, content: '가상화폐 거래소 개발'
    }, {
      id: 2, content: '가상화폐 교환소 개발'
    }, {
      id: 3, content: '가상화폐 P2P 거래소 개발'
    }, {
      id: 4, content: '가상화폐 PAY 솔루션 개발'
    }, {
      id: 5, content: '외부 연동 Restful Webservice(API)모듈 개발'
    },]
  }, {
    id: 3, theme: 'White Paper',
    title: '백서제작',
    list: [{
      id: 1, content: '시장조사 및 플랜'
    }, {
      id: 2, content: '알고리즘 및 구조화'
    }, {
      id: 3, content: '코인 정책 및 블록체인 시스템 연계'
    }, {
      id: 4, content: '다국어 지원'
    }, {
      id: 5, content: '다양한 문서 양식 및 웹페이지 제공'
    },]
  }, {
    id: 4, theme: 'ERP',
    title: '기업의 전사적 자원관리 시스템',
    list: [{
      id: 1, content: '경영정보 시스템<br/>[인사, 급여, 근태, 회계, 영업, 재고 관리]'
    }, {
      id: 2, content: '생산공정 시스템 [원자재 BOM, 불량률, 공정률]'
    }, {
      id: 3, content: '고객관리 시스템 [SCM, 거래처관리, 명함관리]'
    }, {
      id: 4, content: '법률업무 자동화 시스템<br/>[부동산 등기, 상업등기, 송무]'
    }, {
      id: 5, content: '다양한 사무 자동화 시스템'
    },]
  }, {
    id: 5, theme: 'Web Aplication',
    title: '전문적이고 특화된 웹솔루션 개발',
    list: [{
      id: 1, content: '기업 홈페이지 제작'
    }, {
      id: 2, content: '기업 업무용 전문 웹 어플리케이션 제작'
    }, {
      id: 3, content: '웹 크롤링 및 HTML 파싱'
    }, {
      id: 4, content: '다양한 OS, 플랫폼에서의 웹서버 구축'
    }, {
      id: 5, content: '강력한 성능의 WAS'
    },]
  }, {
    id: 6, theme: 'Mobile Platform',
    title: '모바일 솔루션',
    list: [{
      id: 1, content: '음식 배달, 중고차 거래 등 전문 마켓 플랫폼'
    }, {
      id: 2, content: 'Social Commerce'
    }, {
      id: 3, content: 'SNS - Social Network Service'
    }, {
      id: 4, content: '각종 Community 플랫폼'
    }, {
      id: 5, content: 'Live Chat 플랫폼'
    },]
  },
//    {
//    id: 7, theme: 'Internet Of Things',
//    title: '다양한 사물인터넷 생태계구축',
//    list: [{
//      id: 1, content: '아두이노, 라즈베리 에디슨을 활용한 센스제어 모듈'
//    }, {
//      id: 2, content: 'SOM(System On Module)을 확용한 PCB'
//    }, {
//      id: 3, content: 'Artwork'
//    }, {
//      id: 4, content: '안드로이드와 IoT 접목한 무선 원격 제어 플랫폼'
//    }, {
//      id: 5, content: 'NFC, 블루투스, 비콘을 활용한 근거리무선통신 플랫폼'
//    },]
//  }
  ]

  export default {
    name: "Service",
    components: {
      swiper,
      swiperSlide
    },
    data() {
      return {
        swiperOption: {
          autoplay: {delay: 3500,},
//          spaceBetween: 25,
          loop: true,
          slidesPerView: 1,
          centeredSlides: true,
          autoHeight: true,
        },
        S1_DATA, S2_DATA,
      }
    }
  }
</script>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home";
import Solution from "../views/Solution";
import Service from "../views/Service";
//import Support from "../views/Support";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/solution",
    name: "Solution",
    component: Solution,
  },
  {
    path: "/service",
    name: "Service",
    component: Service,
  },
//  {
//    path: "/Support",
//    name: "Support",
//    component: Support,
//  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach(() => {
  window.scrollTo(0, 0)
});

export default router;

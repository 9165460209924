<template>
  <div style="width: 100%">
    <section id="home-s1">
      <swiper :options="swiperOption">
        <swiper-slide>
          <img width="100%" src="../assets/images/home/swiper_1.png"/>
          <div class="box-layout">
            <div class="box">
              <p class="title">
                THE NEXT <span>ERA</span><br/>
                <span>OF THE</span> INTERNET
              </p>
              <p class="desc">
                인터넷 그 다음 시대,<br/>
                당신의 빛나는 아이디어를<br/>
                숙련된 IT전문가팀이 실현시켜 드립니다.
              </p>
              <div class="box-btn-layout">
                <button @click="$router.push('/service')">주요 서비스 바로가기</button>
                <button @click="$router.push('/solution')">거래 솔루션 데모</button>
              </div>
            </div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <img width="100%" src="../assets/images/home/swiper_2.png"/>
          <div class="box-layout">
            <div class="box">
              <p class="title">
                DECENTRALIZATION<br/>
                <span>&</span> BLOCKCHAIN
              </p>
              <p class="desc">
                블록체인기술로 촉발된 탈중앙화 시스템이<br/>
                귀사의 비즈니스와 만났을때<br/>
                어떤 멋진 파괴력이 생길지 상상해 보십시오.
              </p>
              <div class="box-btn-layout">
                <button @click="$router.push('/service')">주요 서비스 바로가기</button>
                <button @click="$router.push('/solution')">거래 솔루션 데모</button>
              </div>
            </div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </swiper-slide>
        <!--        <div class="swiper-pagination" />-->
      </swiper>
    </section>
    <section id="home-s2">
      <div class="section-inner">
        <p class="title">SOLLINE SERVICES</p>
        <img src="../assets/images/home/s2_img.png"/>
        <div class="content-box">
          <div>
            <p class="cb-title">
              Blockchain Core System<br/>
              Crypto Currency Solution<br/>
              Web/Mobile Application<br/>
            </p>
            <p class="cb-desc">
              SOLLINE은 각 개발분야별 해당 전문가가<br/>
              구축 목적과 기능들을 완벽히 분석하여,<br/>
              고객의 요구 사항에 맞는 결과물을 도출해냅니다.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="home-s3">
      <div class="section-inner">
        <p class="title">Crypto Currency Exchange System</p>
        <div class="content-box">
          <img src="../assets/images/home/s3_img.png"/>
          <div>
            <p class="cb-title">
              가상화폐 현물/선물 거래소시스템 구축
            </p>
            <ul>
              <li><img src="../assets/images/home/sec3_img_1.png">비트코인, 이더리움, 리플 등 코인 거래를 제공</li>
              <li><img src="../assets/images/home/sec3_img_2.png">현물거래의 125배 레버리지 까지 지원 가능</li>
              <li><img src="../assets/images/home/sec3_img_3.png">PC / MOBILE 버전 지원</li>
              <li><img src="../assets/images/home/sec3_img_4.png">코인 입출금 시스템</li>
              <li><img src="../assets/images/home/sec3_img_5.png">기타 거래소의 솔루션 추후 제작예정</li>
              <li><img src="../assets/images/home/sec3_img_6.png">경영 관리 및 홍보 지원</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section id="home-s4">
      <div class="section-inner">
        <p class="title">Main Service</p>
        <div class="content-box">
          <div class="sec4-box">
            <div class="box" v-for="m in S4_DATA" :key="m.id">
              <div class="img-layout">
                <img :src="require(`../assets/images/home/s4-${m.id}.png`)"/>
              </div>
              <div class="box-bottom">
                <p class="bb-theme">{{m.theme}}</p>
                <p class="bb-title">{{m.title}}</p>
                <ul>
                  <li v-for="v in m.list" :key="v.id">
                    <img width="10px" height="10px" src="../assets/images/home/icon_diamond.png"/>
                    {{v.content}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="sec4-swiper">
            <swiper class="swiper content-box" :options="swiperOption2">
              <swiper-slide v-for="m in S4_DATA" :key="m.id">
                <div class="box">
                  <div class="img-layout">
                    <img :src="require(`../assets/images/home/s4-${m.id}.png`)"/>
                  </div>
                  <div class="box-bottom">
                    <p class="bb-theme">{{m.theme}}</p>
                    <p class="bb-title">{{m.title}}</p>
                    <ul>
                      <li v-for="v in m.list" :key="v.id">
                        <img width="10px" height="10px" src="../assets/images/home/icon_diamond.png"/>
                        <p v-html="v.content"/>
                      </li>
                    </ul>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div class="btn-layout">
          <button @click="$router.push('/service')">
            메인 서비스 더보기
            <img width="18px" height="18px" src="../assets/images/home/icon_arrow_right.png"/>
          </button>
        </div>
      </div>
    </section>
    <section id="home-s5">

    </section>
  </div>
</template>

<script>
  import {swiper, swiperSlide} from 'vue-awesome-swiper'
  import 'swiper/swiper-bundle.css'

  const S4_DATA = [{
    id: 1, theme: 'Blockchain Core Implementation',
    title: '블록체인 기반 기술 구현',
    list: [{
      id: 1, content: '블록체인 노드 구축'
    }, {
      id: 2, content: '신규 가상화폐 개발'
    }, {
      id: 3, content: '디지털 전자지갑 개발'
    }, {
      id: 4, content: '블록체인 탐색기 개발'
    }, {
      id: 5, content: '블록체인 RPC 통신 모듈 개발'
    },]
  }, {
    id: 2, theme: 'Web Aplication',
    title: '전문적이고 특화된 웹솔루션 개발',
    list: [{
      id: 1, content: '기업 홈페이지 제작'
    }, {
      id: 2, content: '기업 업무용 전문 웹 어플리케이션 제작'
    }, {
      id: 3, content: '웹 크롤링 및 HTML 파싱'
    }, {
      id: 4, content: '다양한 OS, 플랫폼에서의 웹서버 구축'
    }, {
      id: 5, content: '강력한 성능의 WAS'
    },]
  }, {
    id: 3, theme: 'Mobile Platform',
    title: '모바일 솔루션',
    list: [{
      id: 1, content: '음식 배달, 중고차 거래 등 전문 마켓 플랫폼'
    }, {
      id: 2, content: 'Social Commerce'
    }, {
      id: 3, content: 'SNS - Social Network Service'
    }, {
      id: 4, content: '각종 Community 플랫폼'
    }, {
      id: 5, content: 'Live Chat 플랫폼'
    },]
  }]

  export default {
    name: "Home",
    components: {
      swiper,
      swiperSlide
    },
    data() {
      return {
        swiperOption: {
          autoplay: {delay: 3500,},
          loop: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        },
        swiperOption2: {
          autoplay: {delay: 3500,},
//          spaceBetween: 25,
          loop: true,
          slidesPerView: 1,
          centeredSlides: true,
          autoHeight: true,
        },
        S4_DATA,
      }
    },
  }
</script>

<template>
  <div>
    <section id="solution-s1">
      <div class="section-inner">
        <div class="s1-box">
          <div class="hr"/>
          <p class="title">거래소 솔루션</p>
          <p class="desc">
            SOL-LINE 거래소 솔루션은<br/>
            거래소의 본질에 충실한 가장 진보된 형태의 거래소입니다.
          </p>
          <div class="btn-layout">
            <button @click="clickDemo('web')">거래소 데모 Ⅰ<br/><span>WEB</span></button>
            <button @click="clickDemo('mobile')">거래소 데모 Ⅱ<br/><span>MOBILE</span></button>
          </div>
          <p class="desc small">데모 사이트에서 직접 회원가입 하시고 트레이딩 테스트도 가능합니다.</p>
        </div>
      </div>
    </section>
    <section id="solution-s2">
      <div class="section-inner">
        <div class="s2-box">
          <img height="380px" src="../assets/images/solution/s2-img-1.png"/>
          <p class="title">거래소솔루션 데모 Ⅰ</p>
          <!--          <p class="desc">-->
          <!--            가장 베이직한 형태의 거래 환경을 제공하는 거래소 스킨입니다.<br/>-->
          <!--            눈에 익은 편안한 레이아웃, 반응형 제작, 밝은 버전과 어두운 버전을 고객이 선택할 수 있으며,<br/>-->
          <!--            많은 거래소에서 이미 사용하고 있는 형태입니다.-->
          <!--          </p>-->
        </div>
        <div class="s2-box">
          <img height="380px" src="../assets/images/solution/s2-img-2.png"/>
          <p class="title">거래소솔루션 데모 Ⅱ</p>
          <!--          <p class="desc">-->
          <!--            다이나믹한 형태의 거래 환경을 제공하는 거래소 스킨입니다.<br/>-->
          <!--            많은 정보를 소화하는 레이아웃, 반응형 제작, 직관적인 정보의 표현,<br/>-->
          <!--            매수 매도시 호가가 한눈에 들어와 거래에 최적화된 형태입니다.-->
          <!--          </p>-->
        </div>
      </div>
    </section>
    <section id="solution-s3">
      <div class="section-inner">
        <p class="section-title">Exchange solution</p>
        <p class="section-desc">
          가상화폐거래소의 개발 관점에서 큰 축은 거래시스템(Engine)과 지갑(Wallet)으로 구성되어 있습니다.<br/>
          그리고 개발시 가장 중요하게 생각하는 부분은 거래사이트의 안정성(Stability) 및 보안(Security)입니다.
        </p>
        <div class="img-layout">
          <img width="1039px" src="../assets/images/solution/s3-img.png"/>
        </div>
      </div>
    </section>
    <section id="solution-s4">
      <div class="section-inner">
        <p class="section-title">Exchange System Architecture</p>
        <div class="img-layout">
          <img width="1000px" src="../assets/images/solution/s4-img.png"/>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: "Solution",
    methods: {
      isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      },
      clickDemo(type) {
        const result = this.isMobile()
        if (!result) {
          if (type === 'web') {
            window.open('https://demo1.solline.io', '데모 WEB')
          } else {
            window.open('https://demo2.solline.io/', '데모 MOBILE', 'resizable=no,width=374,height=667')
          }
        } else {
          window.open('https://demo1.solline.io')
        }
      },
    }
  };
</script>

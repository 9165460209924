<template>
  <div id="header" :class="scrollTop > 0 ? '' : 'is-top'">
    <div class="header-inner">
      <img class="logo" src="../assets/images/logo.png" @click="menuClick('/')"/>
      <ul>
        <li v-for="m in MENU" :key="m.id"
            :class="{'is-active': $route.path === m.url}"
            @click="menuClick(m.url)">
          {{m.text}}
        </li>
      </ul>
      <img src="../assets/images/home/mobile_menu_2.png" height="20px" v-if="scrollTop > 0"
          @click="$emit('drawer', true)" class="mh-btn"/>
      <img src="../assets/images/home/mobile_menu_1.png" height="20px" v-else
          @click="$emit('drawer', true)" class="mh-btn"/>
    </div>
  </div>
</template>

<script>

  const MENU = [{
    id: 1, text: "Home", url: "/"
  }, {
    id: 2, text: "Solution", url: "/solution"
  }, {
    id: 3, text: "Service", url: "/service"
  },
//    {
//    id: 4, text: "Support", url: "/support"
//  },
  ]

  export default {
    name: "Header",
    data() {
      return {
        MENU, scrollTop: 0, drawer: false,
      }
    },
    methods: {
      handleClose() {
        console.log('test')
      },
      menuClick(v) {
        if(this.$route.path === v) {
          window.scrollTo(0,0)
        } else {
          this.$router.push(v)
          window.scrollTo(0,0)
        }
      },
    },
    created() {
      window.onscroll = () => {
        this.scrollTop = document.documentElement.scrollTop
      };
    }
  }
</script>

<template>
  <div id="app">
    <Header @drawer="drawer= true"/>
    <router-view :style="$route.path === '/support' ? 'min-height: calc(100% - 120px); height: 1px' : ''"/>
    <Footer/>
    <el-drawer
        size="70%"
        :visible.sync="drawer"
        :before-close="handleClose">
      <ul>
        <li v-for="m in MENU" :key="m.id"
            :class="{'is-active': $route.path === m.url}"
            @click="menuClick(m.url)">
          {{ m.text }}
        </li>
      </ul>
    </el-drawer>
    <img src="./assets/images/telegram_icon.png" class="telegram-icon" @click="telegramPopup">
  </div>
</template>

<script>
import Header from "./layout/Header";
import Footer from "./layout/Footer";

const MENU = [{
  id: 1, text: "Home", url: "/"
}, {
  id: 2, text: "Solution", url: "/solution"
}, {
  id: 3, text: "Service", url: "/service"
},
//    {
//    id: 4, text: "Support", url: "/support"
//  },
]

export default {
  name: 'App',
  components: {Header, Footer},
  data() {
    return {
      drawer: false, MENU,
    }
  },
  methods: {
    menuClick(url) {
      this.$router.push(url)
      this.drawer = false
    },
    handleClose() {
      this.drawer = false
    },
    telegramPopup() {
      window.open("/telegram.html")
    }
  }
}
</script>
